<template>
  <div class="infoButon">
    <a-icon class="info" type="question-circle" @click="visible = !visible" />
    <a-modal v-model="visible" :footer="null">
      <div style="padding: 5%" v-html="help"></div>
    </a-modal>
  </div>
</template>

<script>
// import TARGET_VERIFICATION from "@/scripts/request";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocaleArabic, LocaleEnglish } from "../scripts/Locale";

// @ is an alias to /src
export default {
  name: "InfoButton",
  components: {},
  props: {
    help: {
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      locale: null,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },

    info() {
      let self = this;
      const h = this.$createElement;
      this.$info({
        title: `${self.title}`,
        content: h("div", {}, [h("p", `${self.body}`)]),
        onOk() {},
        okText: self.locale.ok,
        cancelText: self.locale.cancel,
      });
    },
  },
  created() {
    this.checkLocal();
  },
};
</script>

<style lang="scss" scoped>
.info {
  transform: scale(2.5);
}
.infoButon {
  width: 97.5vw;
  text-align: end;
}
</style>
