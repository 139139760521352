import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Base',
        component: () => import ('@/views/Home')
    },
    {
        path: '/wholesale',
        name: 'WholeSale',
        component: () => import ('@/views/WholeSale')
    },    {
        path: '/activity',
        name: 'Activity',
        component: () => import ('@/views/Activity')
    },
    {
        path: '/soon',
        name: 'ComingSoon',
        component: () => import ('@/views/ComingSoon')
    }, {
        path: '/lost',
        name: 'LostTRX',
        component: () => import ('@/views/LostTRX')
    },
    {
        path: '/Home',
        name: 'Home',
        component: () => import ('@/views/Home')
    }, {
        path: '/login',
        name: 'Login',
        component: () => import ('@/components/Login')
    }, {
        path: '/profile',
        name: 'Profile',
        component: () => import ('@/views/Profile')
    }, {
        path: '/checkProfile',
        name: 'CheckProfile',
        component: () => import ('@/views/CheckProfile')
    }, {
        path: '/category',
        name: 'SpecificCategory',
        component: () => import ('@/components/SpecificCategory')
    }, {
        path: '/listing',
        name: 'SpecificListing',
        component: () => import ('@/components/SpecificListing')
    }, {
        path: '/voucher',
        name: 'Voucher',
        component: () => import ('@/components/Voucher')
    }, {
        path: '/bill',
        name: 'SpecificBill',
        component: () => import ('@/components/SpecificBill')
    }, {
        path: '/application',
        name: 'SpecificApplication',
        component: () => import ('@/components/Application')
    }, {
        path: '/verification',
        name: 'Verification',
        component: () => import ('@/components/Verification')
    }, {
        path: '/inttopup',
        name: 'IntTopup',
        component: () => import ('@/components/IntTopup')
    }, {
        path: '/carfax',
        name: 'Carfax',
        component: () => import ('@/components/Carfax')
    }, {
        path: '/dynamic',
        name: 'DynamicApplication',
        component: () => import ('@/components/DynamicApplication')
    }, {
        path: '/transfer',
        name: 'Transfer',
        component: () => import ('@/components/Transfer')
    }, {
        path: '/parkmeterfine',
        name: 'Parkmeter',
        component: () => import ('@/components/Parkmeter')
    }, {
        path: '/costs',
        name: 'Costs',
        component: () => import ('@/components/Costs')
    }, {
        path: '/calendar',
        name: 'Calendar',
        component: () => import ('@/components/Calendar')
    }, {
        path: '/targets',
        name: 'Targets',
        component: () => import ('@/components/Targets')
    }, {
        path: '/cc',
        name: 'CC',
        component: () => import ('@/components/CreditCard')
    }, {
        path: '/phone',
        name: 'Phone',
        component: () => import ('@/components/Phone')
    }, {
        path: '/map',
        name: 'Map',
        component: () => import ('@/components/Map')
    }, {
        path: '/qr',
        name: 'QR',
        component: () => import ('@/components/QR')
    }, {
        path: '/test',
        name: 'PaymentSuccess',
        component: () => import ('@/components/PaymentSuccess')
    },
];

const router = new VueRouter({mode: 'history', routes});

export default router;
