export const LocaleArabic = {
    hello: `marhaba`,
    changeLanguage: "مطلوب إعادة التشغيل لتغيير اللغة إلى الإنجليزية",
    continue: "استمر",
    cancel: "إلغاء",
    nothingToSeeHere: "لا يوجد شيء تراه هنا.",
    switch: "تحول",
    devices: "الأجهزة",
    deviceid: "معرف الجهاز",
    currentDevice: "الجهاز الحالي",
    version: "إصدار",
    startdate: "تاريخ البدء",
    brand: "ماركة",
    model: "نموذج",
    sales7days: "المبيعات (آخر 7 أيام)",
    delete: "حذف",
    creditCards: "بطاقات الائتمان",
    outlets: "منافذ",
    total: "مجموع",
    preview: "معاينة",
    startpreview: "بدء المعاينة",
    review: "إعادة النظر",
    limitsreached: "تم الوصول إلى الحدود \n الرجاء حذف الصورة الموجودة والمحاولة مرة أخرى.",
    infofor: "معلومات عن",
    paymentoptions: "خيارات الدفع",
    reportReady: "تقريرك جاهز.",
    linkToCarfax: "رابط كارفاكس",
    nickname: "اسم الشهرة",
    cardNumber: "رقم البطاقة",
    cardHolders: "حامل البطاقة",
    expirationDate: "تاريخ انتهاء الصلاحية",
    month: "شهر",
    year: "سنة",
    submit: "إرسال",
    success: "نجاح",
    successfullyAdded: " أضيف بنجاح",
    pleaseFillAllEmptyBlocks: "من فضلك املأ كل الكتل الفارغة!",
    pleaseselectyourcountry: "الرجاء تحديد بلدك!",
    country: "دولة",
    billType: "نوع الفاتورة",
    emptyFieldsWarning: "الرجاء تعبئة البيانات الناقصة",
    intTopupLabel: "تعبئة الرصيد الدولية",
    other: "آخر",
    proceed: "تقدم",
    quantity: "كمية",
    topup: "تعبئة الرصيد",
    confirm: "تأكيد",
    login: "تسجيل الدخول",
    loginByPhone: "تسجيل الدخول باستخدام رقم هاتفك",
    goto: "اذهب إلى",
    servers: "الخوادم",
    notifications: "إشعارات",

    acc_accountId: "معرف الحساب",
    acc_accountName: "أسم الحساب",
    acc_phone: "هاتف",
    acc_session: "جلسة",
    acc_device: "جهاز",
    acc_version: "إصدار",
    acc_build: "البناء",

    logout: "تسجيل خروج",

    delete_cc: "هل أنت متأكد أنك تريد حذف بطاقة الائتمان هذه؟",
    code: "الشفرة",

    district: "منطقة",
    date: "تاريخ",
    invoice_balance: "رصيد الفاتورة",
    invoice_fees: "رسوم الفاتورة",
    amount_to_be_paid: "المبلغ المستحق للدفع",
    price: "سعر",
    no_tickets: "لا يوجد غرامات",
    refresh_cards: "تحديث البطاقات",

    address_1: "العنوان الأول",
    address_2: "العنوان الثاني",
    full_name: "الاسم الكامل",
    phone_number: "رقم الهاتف",
    change: "غير",
    save: "حفظ",
    cam_on: "قم بتشغيل الكاميرا",
    price_to_pay: "مبلغ الدفع",
    country_code: "الرقم الدولي",
    must_be_between: "يجب أن يكون بين",
    and: "و",
    print: "اطبع",

    transaction_id: "رقم المعاملة",
    amount: "كمية",
    sn: "رقم سري",
    secret_code: "الرمز السري",
    expiry_date: "تاريخ الانتهاء",

    yes: "نعم",
    delete_image_warning: "هل أنت متأكد من حذف هذه الصورة؟",

    picture_capture_error: "تعذر التقاط الصورة ، يرجى المحاولة مرة أخرى.",

    cc_not_available: "إعادة التعبئة عن طريق بطاقة الائتمان غير متوفرة الآن ، يرجى المحاولة مرة أخرى في وقت لاحق.",
    checkout: "الدفع",

    coming_soon: "قريبا...",
    profile: "الملف الشخصي",
    fees: "مصاريف",
    balance: "رصيد",
    plate_number: "رقم لوحة",
    id: "هوية شخصية ",
    ltn: "LTN",


}
export const LocaleEnglish = {
    hello: `hello`,
    changeLanguage: "Restart is required to change language to Arabic",
    continue: "Continue",
    cancel: "Cancel",
    nothingToSeeHere: "There's nothing to see here.",
    switch: "switch",
    devices: "Devices",
    deviceid: "Device ID",
    currentDevice: "Current Device",
    version: "Version",
    startdate: "Start Date",
    brand: "Brand",
    model: "Model",
    sales7days: "Sales (Last 7 Days)",
    delete: "Delete",
    creditCards: "Credit cards",
    outlets: "Outlets",
    total: "Total",
    preview: "Preview",
    startpreview: "start preview",
    review: "Review",
    limitsreached: "Limits reached\nPlease delete the existing picture and try again.",
    infofor: "Info For",
    paymentoptions: "Payment Options",
    reportReady: "Your report is ready.",
    linkToCarfax: "Link to carfax",
    nickname: "Nickname",
    cardNumber: "Card Number",
    cardHolders: "Card Holder",
    expirationDate: "Expiration Date",
    month: "Month",
    year: "Year",
    submit: "Submit",
    success: "Success",
    successfullyAdded: " successfully added",
    pleaseFillAllEmptyBlocks: "please fill all empty blocks!",
    pleaseselectyourcountry: "Please select your country!",
    country: "Country",
    billType: "Bill type",
    emptyFieldsWarning: "Please fill missing data",
    intTopupLabel: "International Top-up",
    other: "OTHER",
    proceed: "Proceed",
    quantity: "Quantity",
    topup: "Top-up",
    confirm: "Confirm",
    login: "Login",
    loginByPhone: "Login using your phone number",
    goto: "Go To",
    ok: "Ok",
    servers: "Servers",
    notifications: "Notifications",

    acc_accountId: "ACCOUNT ID",
    acc_accountName: "ACCOUNT NAME",
    acc_phone: "PHONE",
    acc_session: "SESSION",
    acc_device: "DEVICE",
    acc_version: "VERSION",
    acc_build: "BUILD",

    logout: "Logout",

    delete_cc: "Are you sure you want to delete this credit card?",

    code: "Code",
    district: "District",
    date: "Date",
    invoice_balance: "Invoice balance",
    invoice_fees: "Invoice fees",
    amount_to_be_paid: "Amount to be paid",
    price: "Price",
    no_tickets: "No tickets found",
    refresh_cards: "Refresh cards",

    address_1: "Address line 1",
    address_2: "Address line 2",
    full_name: "Full Name",
    phone_number: "Phone Number",

    change: "Change",
    save: "Save",
    cam_on: "Turn camera on",

    price_to_pay: "Price to pay",
    country_code: "Country code",

    must_be_between: "must be between",
    and: "and",
    print: "Print",
    transaction_id: "Transaction ID",
    amount: "Amount",
    sn: "SN",
    secret_code: "Secret Code",
    expiry_date: "Expiry Date",
    yes: "Yes",
    delete_image_warning: "Are you sure you want to delete this image?",
    picture_capture_error: "Picture could not be captured please try again.",
    cc_not_available: "Top-up by credit  card not available right now, please try again later.",
    checkout: "Checkout",
    coming_soon: "Coming Soon...",
    profile: "Profile",
    fees: "Fees",
    balance: "Balance",
    plate_number: "Plate Number",
    id: "ID ",
    ltn: "LTN",
    description: "Description",
    vin: "VIN",
    promo_code: "Promo Code",
    password: "Password",
    status: "Status",
    view_report: "View Report ",
    view_receipt: "View Receipt ",
    from_void: "VOID SALE",
    voided: "VOIDED",
    lost: "LOST",

}