<template>
  <div v-if="loading" class="loading">
    <img
      :src="require('../assets/loading.gif')"
      width="100px"
      alt="Loading..."
    />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
// @ is an alias to /src

export default {
  name: "Loading",
  components: {},
  props: {
    loading: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.loading {
  background: rgba($color: grey, $alpha: 0.8);
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  img {
    z-index: 1000;
    position: relative;
    top: 45%;
  }
}
</style>
