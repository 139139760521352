<template>
  <div id="app" :dir="$session.get('direction')">
    <router-view />
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  beforeMount() {
    if (this.$session.get("lang")) {
      document.getElementById("app").style.direction = `${this.$session.get(
        "direction"
      )}`;
      console.warn("direction changed to ", this.$session.get("direction"));
    }
    //checks if user signs in or out
    console.log(this.$session.get("loggedInbyPhoneProvider") === true);
    if (this.$session.get("loggedInbyPhoneProvider") === true) {
      if (this.$route.path === "/login") {
        this.$router.replace("/home").catch(() => {});
      }
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          // if we dont have a user || not logged in
          this.$router.replace("/login").catch(() => {
            // console.log(error);
          });
        } else if (this.$route.path === "/login") {
          this.$router.replace("/home").catch(() => {});
        }
      });
    }
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .hide-on-phone {
    display: none;
  }
  .show-on-phone {
    display: initial;
  }
}
@media only screen and (min-width: 600px) {
  .hide-on-phone {
    display: initial;
  }
  .show-on-phone {
    display: none;
  }
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .ant-form-item {
    direction: ltr;
  }
}

.sticky {
  position: fixed;
  width: 16% !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
