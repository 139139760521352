<template>
  <div class="verification" v-if="banner">
    <div class="banner">
      <router-link
        v-if="banner.action === 'verification'"
        :to="{
          name: 'Profile',
          params: { target: banner.action },
        }"
      >
        <p>{{ banner.text }}</p>
      </router-link>
      <router-link
        v-else
        :to="{
          name: 'Profile',
          params: { target: null },
        }"
      >
        <p>{{ banner.text }}</p>
      </router-link>
    </div>
    <div v-if="banner" class="banner-protection"></div>
  </div>
</template>

<script>
// import TARGET_VERIFICATION from "@/scripts/request";
import "bootstrap/dist/css/bootstrap.min.css";
// @ is an alias to /src
export default {
  name: "Banner",
  components: {},
  props: {
    banner: {
      required: true,
    },
  },
  data() {
    return {
      // verf_target: TARGET_VERIFICATION,
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 20px;
  background: #fffcdf;
  left: 0;
  box-shadow: 7px 6px 5px grey;
  a {
    color: black;
  }
}
.banner-protection {
  position: absolute;
  width: 100%;
  height: 100%;
  background: lightgray;
  left: 0;
  z-index: 998;
  opacity: 0.5;
}
</style>
