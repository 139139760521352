<template>
  <div class="container-fluid">
    <div class="header">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";

// import "slick-carousel/slick/slick.css";
// import axios from "axios";
// import firebase from "firebase";
// import Slick from "vue-slick";

export default {
  name: "PageHeader",
  props: { title: String },
  components: {},
  data() {
    return {};
  },
  methods: {},
  created: function() {},
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  .header {
    h2 {
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    padding: 20px;
    background: #e6e6e6;
    box-shadow: 7px 6px 5px grey;
  }
}
</style>
