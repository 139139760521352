import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from "firebase";
import axios from 'axios';
import VueSession from 'vue-session';
import {
    BootstrapVue
} from 'bootstrap-vue';
import Antd from 'ant-design-vue';
import VueMask from 'v-mask';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueQrcodeReader from "vue-qrcode-reader";
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueHtmlToPaper from 'vue-html-to-paper';

import Banner from './components/Banner.vue';
import InfoButton from "./components/InfoButton.vue";
import PageHeader from './components/PageHeader.vue';
import Loading from "@/components/Loading";
import SideMenu from "@/components/SideMenu"

import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
import store from './store';
import {
    LocaleArabic,
    LocaleEnglish
} from "@/scripts/Locale";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyANxjQCaQz-7TqwSjmyb7vlzMcwGbq7WZg",
    authDomain: "whishdraft.firebaseapp.com",
    projectId: "whishdraft",
    storageBucket: "whishdraft.appspot.com",
    messagingSenderId: "380573071396",
    appId: "1:380573071396:web:ec7549653c25cac59cc0b1",
    measurementId: "G-NBFCJP7HBX"
};

firebase.initializeApp(firebaseConfig);

let options = {
    persist: true
};

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAnLbnqoZs-R7UjeiG61ff88W_RtJLDfdM'
    }
});

Vue.use(vueNumeralFilterInstaller, {
    locale: 'en-gb'
});

const printOptions = {
    name: '_blank',
    specs: [
        'fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, printOptions);

Vue.use(Antd);

Vue.use(VueMask);

Vue.use(VueSession, options);

Vue.use(VueQrcodeReader);

Vue.component("MyBanner", Banner);
Vue.component("info-button", InfoButton);
Vue.component("page-header", PageHeader);
Vue.component("loading", Loading);
Vue.component("side-menu", SideMenu);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.use(firebase);

// if needed to make a global function use this one:

Vue.mixin({
    methods: {
        tester() {
            this.paymentOptionsVisible = true;
        },
        getMainAxios() {
            return axios.create({
                headers: {
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Origin": "*",
                    language: this.$session.get("lang") || "en",
                    itelVersion: "1000",
                    uid: "uidtest1.3",
                    brand: "Web",
                    model: "Web",
                    osVersion: "unix",
                    operatingSystem: "4",
                    sessionId: `${this.$session.get("sessionId")}`,
                    token: `${this.$session.get("token")}`,
                    deviceId: `${this.$session.get("deviceid")}`,
                    sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
                    counter: `${this.$session.get("counter")}`,
                },
            });
        },
        checkApiRes(result, apiBody, req, reqType) {

            let locale = {};
            if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
                locale = LocaleArabic;
            } else {
                locale = LocaleEnglish;
            }

            let axiosInstance = this.getMainAxios();
            // console.log(apiBody, req);
            if (result.headers.sessioncounter) {
                this.$session.set(
                    "sessionCounter",
                    result.headers.sessioncounter
                );
            }
            if (!result.data.status) {
                // error
                console.error(result);
                this.$message.error(result.data.dialog.message);
                let code = result.data.code
                if (
                    code === "auth.session_expired" ||
                    code === "auth.session_not_exist" ||
                    code === "auth.session_expired" ||
                    code === "auth.device_not_active" ||
                    code === "auth.session_not_exist" ||
                    code === "auth.wrong_token" ||
                    code === "auth.account_not_active" ||
                    code === "auth.device_not_exists"
                ) {
                    // log user out
                    axiosInstance.post(`${this.$session.get("baseURI")}account/session/deactivate`, {
                            sessionId: this.$session.get("sessionId"),
                        })
                        .then(() => {
                            this.$session.clear();
                            firebase
                                .auth()
                                .signOut()
                                .then(() => {
                                    this.$router.replace("/login").catch(() => {});
                                    // Sign-out successful.
                                })
                                .catch((error) => {
                                    console.log(error);
                                    // An error happened.
                                });
                        });
                } else if (code === "sales.account_balance_insufficient") {
                    // if dialog show dialog with title and msg, buttons should be
                    // credit card {open page as if user is purchasing with credit card}
                    // and
                    // topup account{if user is at main page make top-up and expand item at 0 else take him to the page and open topup}
                    // send to route with target = topup if it checks then doc.geteltbyid.click on the topup in the side menu,
                    // else if cc this.paymentoptionvisible is true
                    if (result.data.dialog) {
                        //    show dialog
                        let him = this;
                        this.$confirm({
                            title: result.data.dialog.title,
                            content: () => < div > {
                                result.data.dialog.message
                            } < /div>,
                            okText: locale.creditCards,
                            cancelText: locale.topup,
                            onOk() {
                                him.paymentOptionsVisible = false;
                                him.paymentOptionsVisible = true;
                            },
                            onCancel() {
                                this.$router.push({
                                    name: "Home",
                                    params: {
                                        topup: true
                                    }
                                });
                            },
                        });
                    }
                } else if (code === "global.wrong_server") {
                    // GET (https://whish.money/itel-service/global/myserver) got to main menu and refresh all cached values
                    axiosInstance.get(`${this.$session.get('baseURI')}global/myserver`).then(result => {
                        if (result.status) {
                            let body = result.data;
                            this.$session.remove('menuRoot');
                            this.$session.remove('topupAccount');
                            this.$session.remove('menu');
                            this.$session.set("currency", body.currency)
                            this.$session.set("baseURI", body.api)
                            this.$session.set("currencyFormat", body.currencyFormat)
                            this.$session.set("currencyString", body.currencyString)
                            window.location.href = "/";
                        }
                    })
                } else if (code === "purchase.confirm") {
                    // already done check it
                    // let the user know the price from extra.price if confirm continue with price from extra if cancel got to main menu
                    let him = this;
                    this.$confirm({
                        title: result.data.dialog.title,
                        content: () => < div > {
                            result.data.dialog.message
                        } < /div>,
                        okText: locale.ok,
                        cancelText: locale.cancel,
                        onOk() {
                            apiBody.price = result.data.extra.price;
                            let request = him.getAxios().post(`${him.$session.get("baseURI")}${req}`, apiBody);

                            if (reqType.toLowerCase() === "post") {
                                request = him.getAxios().post(`${him.$session.get("baseURI")}${req}`, apiBody);

                            } else if (reqType.toLowerCase() === "get") {
                                request = him.getAxios().get(`${him.$session.get("baseURI")}${req}`, apiBody);

                            }
                            if (request) {
                                request.then((result) => {
                                    if (result.headers.sessioncounter) {
                                        him.$session.set("sessionCounter", result.headers.sessioncounter);
                                    }
                                    if (!result.data.status) {
                                        // error
                                        him.$message.error(result.data.dialog.message);
                                        console.log(" error : ", result);
                                    } else {
                                        // ok
                                        console.log("change price ok", result);
                                        him.$confirm({
                                            title: result.data.dialog.title,
                                            content: () => < div > {
                                                result.data.dialog.message
                                            } < /div>,
                                            okText: locale.ok,
                                            cancelText: locale.cancel,
                                            onOk() {
                                                console.log('OK');
                                            },
                                            onCancel() {
                                                console.log('Cancel');
                                            },
                                        });
                                    }
                                });
                            } else {
                                him.$message.error("Please report this error.");
                                return false;
                            }
                        },
                        onCancel() {
                            him.$router.replace("/").catch(() => {});
                        },
                    });
                } else if (code === "refresh.denominations") {
                    //    clear the cache for products and side menu then refresh
                    if (this.$router.currentRoute.path === "/" || this.$router.currentRoute.path === "/Home") {
                        this.$session.remove('menuRoot');
                        this.$session.remove('topupAccount');
                        this.$session.remove('menu');
                        window.location.reload();
                    } else {
                        this.$session.remove('menuRoot');
                        this.$session.remove('topupAccount');
                        this.$session.remove('menu');
                        this.$route.push({
                            name: 'Home'
                        }).catch(() => {});
                    }
                } else if (code === "sales.account_balance_insufficient_no_cc") {
                    // same as insufficient balance but without dialogue got to side menu and expand item at 0 from it
                    this.$router.push({
                        name: "Home",
                        params: {
                            topup: true
                        }
                    }).catch(() => {});
                } else if (code === "sales.lost_transactions_exist") {
                    // open new page and call GET: transaction/lost/new
                    axiosInstance.get(`${this.$session.get('baseURI')}transaction/lost/new`).then((result) => {
                        if (result.data.dialog) {
                            this.$error({
                                title: result.data.dialog.title,
                                content: result.data.dialog.message,
                            });
                        } else {
                            this.$router.push({
                                name: "LostTRX",
                                params: {
                                    data: result.data
                                }
                            }).catch(() => {});
                        }
                    })
                } else if (code === "app.update.needUpdate") {
                    // not applicable
                }
                return false;
            } else {
                return true;
            }
        },
    },
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');